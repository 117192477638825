import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  guestCommunicationKeys,
  useGuestCommunicationCache,
} from "src/data/guestCommunication/queries/guestCommunicationQueryCache"
import {
  IGuestCommunicationChannelsResponse,
  IGuestCommunicationError,
  IGuestCommunicationRule,
  IGuestCommunicationRuleTemplate,
  IGuestCommunicationTriggerItem,
  IPatchGuestCommunicationRule,
  IPostGuestCommunicationRule,
  IShortcode,
  ISMSRestrictedCountry,
} from "src/data/guestCommunication/types/guestCommunicationTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

// ts-prune-ignore-next
export function useFetchGuestCommunicationRule({
  orgId,
  ruleId,
  options,
}: {
  orgId: string
  ruleId: string
  options?: UseQueryOptions<
    /* the data type of what is kept in the cache: */
    IGuestCommunicationRule,
    /* Error type; since we use axios, it can be hard-coded to AxiosError: */
    AxiosError,
    /* the data type returned by the request (usually the same as cache data): */
    IGuestCommunicationRule,
    /* The data type of the query key: */
    ReturnType<typeof guestCommunicationKeys.rule>
  >
}) {
  async function fetchGuestCommunicationRule(orgId: string, ruleId: string) {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/organizations/${orgId}/guest_communication/rules/${ruleId}`
    )
    return response.data
  }

  return useQuery(
    guestCommunicationKeys.rule(orgId, ruleId),
    () => fetchGuestCommunicationRule(orgId, ruleId),
    {
      ...options,
    }
  )
}

export function useFetchGuestCommunicationRules({
  orgId,
  options,
}: {
  orgId: string
  options?: UseQueryOptions<
    /* the data type of what is kept in the cache: */
    IGuestCommunicationRule[],
    /* Error type; since we use axios, it can be hard-coded to AxiosError: */
    AxiosError,
    /* the data type returned by the request (usually the same as cache data): */
    IGuestCommunicationRule[],
    /* The data type of the query key: */
    ReturnType<typeof guestCommunicationKeys.rulesList>
  >
}) {
  async function fetchGuestCommunicationRules(orgId: string) {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/organizations/${orgId}/guest_communication/rules`
    )
    return response.data.rules
  }

  return useQuery(
    guestCommunicationKeys.rulesList(orgId),
    () => fetchGuestCommunicationRules(orgId),
    {
      keepPreviousData: true,
      ...options,
    }
  )
}

export function usePostGuestCommunicationRule() {
  const queryClient = useQueryClient()

  async function postGuestCommunicationRule(
    orgId: string,
    data: IPostGuestCommunicationRule
  ) {
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/organizations/${orgId}/guest_communication/rules`,
      data
    )
    const rule: IGuestCommunicationRule = response.data
    return rule
  }

  return useMutation<
    IGuestCommunicationRule,
    AxiosError<IGuestCommunicationError>,
    { orgId: string; data: IPostGuestCommunicationRule }
  >(
    ({ orgId, data }: { orgId: string; data: IPostGuestCommunicationRule }) =>
      postGuestCommunicationRule(orgId, data),
    {
      onSuccess: (newRule: IGuestCommunicationRule) => {
        queryClient.invalidateQueries(
          guestCommunicationKeys.organization(newRule.organization_id)
        )
      },
    }
  )
}

export function usePatchGuestCommunicationRule() {
  const queryClient = useQueryClient()
  const { updateRuleInCache } = useGuestCommunicationCache()

  async function patchGuestCommunicationRule(
    orgId: string,
    ruleId: string,
    _data: IPatchGuestCommunicationRule
  ) {
    const data = { ..._data }
    if (_data.id) {
      delete data.id // ensure the rule id is never sent in the patch request
    }
    const response = await minutApiHttpClient.patch(
      `${API_DEFAULT}/organizations/${orgId}/guest_communication/rules/${ruleId}`,
      data
    )
    const rule: IGuestCommunicationRule = response.data
    return rule
  }

  return useMutation<
    IGuestCommunicationRule,
    AxiosError<IGuestCommunicationError>,
    { orgId: string; ruleId: string; data: IPatchGuestCommunicationRule }
  >(
    ({ orgId, ruleId, data }) =>
      patchGuestCommunicationRule(orgId, ruleId, data),
    {
      onMutate: ({ orgId, ruleId, data }) => {
        updateRuleInCache(orgId, ruleId, (cachedRule) => ({
          ...cachedRule,
          ...data,
        }))
      },
      onSettled: (newRule) => {
        if (newRule?.organization_id) {
          queryClient.invalidateQueries(
            guestCommunicationKeys.organization(newRule?.organization_id)
          )
        }
      },
    }
  )
}

export function useDeleteGuestCommunicationRule() {
  const queryClient = useQueryClient()

  async function deleteGuestCommunicationRule(orgId: string, ruleId: string) {
    const response = await minutApiHttpClient.delete(
      `${API_DEFAULT}/organizations/${orgId}/guest_communication/rules/${ruleId}`
    )
    const rule: IGuestCommunicationRule = response.data
    return rule
  }

  return useMutation(
    ({ orgId, ruleId }: { orgId: string; ruleId: string }) =>
      deleteGuestCommunicationRule(orgId, ruleId),
    {
      onSuccess: (_, { orgId }) => {
        queryClient.invalidateQueries(
          guestCommunicationKeys.organization(orgId)
        )
      },
    }
  )
}

export function useFetchGuestCommunicationShortcodes({
  options,
}: {
  options?: UseQueryOptions<
    IShortcode[],
    AxiosError,
    IShortcode[],
    ReturnType<typeof guestCommunicationKeys.shortcodes>
  >
}) {
  async function fetchGuestCommunicationShortcodes() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/guest_communication/shortcodes`
    )
    return response.data.shortcodes
  }

  return useQuery(
    guestCommunicationKeys.shortcodes(),
    () => fetchGuestCommunicationShortcodes(),
    options
  )
}

export function useFetchGuestCommunicationTriggers({
  options,
}: {
  options?: UseQueryOptions<
    IGuestCommunicationTriggerItem[],
    AxiosError,
    IGuestCommunicationTriggerItem[],
    ReturnType<typeof guestCommunicationKeys.triggersList>
  >
}) {
  async function fetchGuestCommunicationTriggers() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/guest_communication/triggers`
    )
    return response.data.triggers
  }

  return useQuery(
    guestCommunicationKeys.triggersList(),
    () => fetchGuestCommunicationTriggers(),
    { staleTime: 1000 * 60 * 60, ...options }
  )
}

export function useFetchGuestCommunicationRuleTemplates({
  options,
}: {
  options?: UseQueryOptions<
    IGuestCommunicationRuleTemplate[],
    AxiosError,
    IGuestCommunicationRuleTemplate[],
    ReturnType<typeof guestCommunicationKeys.ruleTemplatesList>
  >
}) {
  async function fetchGuestCommunicationRuleTemplates() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/guest_communication/rule_templates`
    )
    return response.data.rule_templates
  }

  return useQuery(
    guestCommunicationKeys.ruleTemplatesList(),
    () => fetchGuestCommunicationRuleTemplates(),
    { staleTime: 1000 * 60 * 60, ...options }
  )
}

export function useFetchGuestCommunicationChannels({
  options,
}: {
  options?: UseQueryOptions<
    IGuestCommunicationChannelsResponse,
    AxiosError,
    IGuestCommunicationChannelsResponse,
    ReturnType<typeof guestCommunicationKeys.channelsList>
  >
}) {
  async function fetchGuestCommunicationChannels() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/guest_communication/channels`
    )
    return response.data
  }

  return useQuery(
    guestCommunicationKeys.channelsList(),
    () => fetchGuestCommunicationChannels(),
    { staleTime: 1000 * 60 * 60, ...options }
  )
}

export function useFetchHomeShortcodes({ homeId }: { homeId: string }) {
  async function fetchHomeShortcodes(homeId: string) {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/homes/${homeId}/guest_communication/shortcodes`
    )
    const shortcodes: IShortcode[] = response.data.shortcodes
    return shortcodes
  }

  return useQuery(guestCommunicationKeys.homeShortcodes(homeId), () =>
    fetchHomeShortcodes(homeId)
  )
}

export function useFetchSMSRestrictedCountries({
  options,
}: {
  options?: UseQueryOptions<
    ISMSRestrictedCountry[],
    AxiosError,
    ISMSRestrictedCountry[],
    ReturnType<typeof guestCommunicationKeys.smsRestrictedCountries>
  >
}) {
  async function fetchSMSRestrictedCountries() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/guest_communication/sms_restrictions`
    )

    return response.data
  }

  return useQuery(
    guestCommunicationKeys.smsRestrictedCountries(),
    () => fetchSMSRestrictedCountries(),
    { ...options }
  )
}
