import { useQueryClient } from "@tanstack/react-query"

import { IGuestCommunicationRule } from "src/data/guestCommunication/types/guestCommunicationTypes"

export const guestCommunicationKeys = {
  all() {
    return ["guestCommunication"] as const
  },

  shortcodes() {
    return [...this.all(), "shortcodes"] as const
  },

  /** Use to target specific cached home shortcodes, by id */
  homeShortcodes(homeId: string) {
    return [...this.all(), "shortcodes", homeId] as const
  },

  triggersList() {
    return [...this.all(), "triggersList"] as const
  },

  ruleTemplatesList() {
    return [...this.all(), "ruleTemplatesList"] as const
  },

  channelsList() {
    return [...this.all(), "channelsList"] as const
  },

  smsRestrictedCountries() {
    return [...this.all(), "smsRestrictedCountries"] as const
  },

  organization(orgId: string) {
    return [...this.all(), "organization", orgId] as const
  },

  rule(orgId: string, ruleId: string) {
    return [...this.organization(orgId), "rules", ruleId] as const
  },

  rulesList(orgId: string) {
    return [...this.organization(orgId), "rulesList"] as const
  },
} as const

export function useGuestCommunicationCache() {
  const queryClient = useQueryClient()

  function updateRuleInCache(
    orgId: string,
    ruleId: string,
    updater: (cachedRule: IGuestCommunicationRule) => IGuestCommunicationRule
  ) {
    queryClient.setQueriesData<IGuestCommunicationRule[]>(
      guestCommunicationKeys.rulesList(orgId),
      (rules) => {
        if (!rules) {
          return []
        }

        return rules.map((r) => (r.id === ruleId ? updater(r) : r))
      }
    )

    const cachedRule = queryClient.getQueryData<IGuestCommunicationRule>(
      guestCommunicationKeys.rule(orgId, ruleId)
    )

    if (cachedRule) {
      queryClient.setQueryData<IGuestCommunicationRule>(
        guestCommunicationKeys.rule(orgId, ruleId),
        (rule) => {
          return updater(rule as IGuestCommunicationRule)
        }
      )
    }
  }

  return {
    updateRuleInCache,
  }
}
